@import "./styles/partials/main";

::-webkit-scrollbar {
  display: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}
ul {
  list-style: none;
  margin: 0;
}

a {
  text-decoration: none;
  display: inline-block;
  color: #000;

  &:hover {
    text-decoration: none;
    text-decoration: none;
    display: inline-block;
    color: #000;
  }
}

button {
  cursor: pointer;
}

img {
  display: block;
}

p {
  margin-bottom: 0;
}
h5 {
  margin-bottom: 0;
}

* {
  margin: 0;
  padding: 0;
  font-family: inherit;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  font-size: inherit;
}

html {
  box-sizing: border-box;
  /* font-size: 62.5%;
  @media (max-width: 992px) {
    font-size: 50%;
  } */
}

body {
  font-family: "Satoshi";
  color: #000;
  /* font-weight: 400;
  line-height: 1.4;
  font-size: inherit; */
}

.loader-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  animation: spin 2s linear infinite;
}

.textInput {
  border: 1px solid #bfbfbf;
  padding: 12px 16px;
  border-radius: 4px;
  outline: none;
  flex: 1;
  font-size: 14px;
}

.linkButton {
  border: none;
  background: transparent;
  color: #008135;
}

.primaryButton {
  background-color: #008135;
  border: none;
  color: #fff;
  border-radius: 32px;
  display: flex;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
}

.passwordToggeWrapper {
  /* // border: 1px solid #bfbfbf;
  // padding: 12px 16px; */
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
  position: relative;
}

.passwordToggleIconWrapper {
  position: absolute;
  right: 10px;
  display: flex;
}

.error {
  color: #dc2626;
}

.success {
  margin-top: 10px;
  text-align: center;
  color: #22c55e;
}

.textInputErrorState {
  border: 1px solid #d92d20;
  background-color: #fef3f2;
}

.errorStateContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
  font-size: 12px;
}

.loader-wrapper {
  /* position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fafafa;
  opacity: 0.9;
  display: grid;
  place-items: center; */
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fafafa;
  opacity: 0.9;
  display: grid;
  place-items: center;
  z-index: 9;
}

.loader {
  border: 8px solid #e3e3e3;
  border-top: 8px solid #008135;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
